import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Observer } from '@catalogo/theme-observer';
import AspectRatio from './components/aspect-ratio';

const B2wadsGoogle = ({ publication }) => {
  const { _id: publisherId, zionSlideShow, networkCode, adUnitPath, ratio } = publication;
  const [isLoaded, setLoaded] = useState(false);
  const id = `div-gpt-ad-${publisherId}`;
  const enableLazyLoad = !zionSlideShow;
  useEffect(() => {
    setLoaded(true);

    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => {
      window.googletag.pubads().collapseEmptyDivs();
      window.googletag
        .defineSlot(`/${networkCode}/${adUnitPath}`, ['fluid'], `div-gpt-ad-${publisherId}`)
        .addService(window.googletag.pubads());
      enableLazyLoad && window.googletag.pubads().enableSingleRequest();
      enableLazyLoad &&
        window.googletag.pubads().enableLazyLoad({
          fetchMarginPercent: 150,
          renderMarginPercent: 200,
          mobileScaling: 2.0,
        });
      window.googletag.enableServices();
    });
  }, []);

  const googleTagDisplayAd = () => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(() => {
      window.googletag.display(id);
    });
  };

  const renderNativeAd = () => <WrapperAds id={id}>{!isLoaded ? null : googleTagDisplayAd()}</WrapperAds>;

  return (
    <>
      {!zionSlideShow ? (
        <AspectRatio ratio={ratio}>{renderNativeAd()}</AspectRatio>
      ) : (
        <Wrapper>
          <AspectRatio ratio={ratio}>
            <Observer onceVisible={() => googleTagDisplayAd()}>
              <div>
                <WrapperAds id={id} />
              </div>
            </Observer>
          </AspectRatio>
        </Wrapper>
      )}
    </>
  );
};
const WrapperAds = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
`;

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100px;
  overflow: hidden;
`;

B2wadsGoogle.propTypes = {
  publication: PropTypes.object,
};

export default B2wadsGoogle;
