import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const getPaddingRatio = ratio => {
  if (!ratio) {
    return '0';
  }
  const splitted = ratio.split(':');

  return `${(splitted[1] / splitted[0]) * 100}%`;
};

const AspectRatio = ({ children, ratio }) => <WrappedRatio ratio={ratio}>{children}</WrappedRatio>;

const WrappedRatio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f7f7f7;
  width: 100%;
  padding-top: ${({ ratio }) => getPaddingRatio(ratio)};
`;

AspectRatio.propTypes = {
  children: PropTypes.object,
  ratio: PropTypes.string,
};

export default AspectRatio;
